import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
//import Image from "../components/image"
import SEO from "../components/seo"
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw'


const AboutPetneck2Programme = ({data: {strapiAboutProgramme}}) => (
  <Layout>
   
    <SEO title="PETNECK2 About the Programme"/>

    <div class="content">
      <div class="title">
        <p><ReactMarkdown rehypePlugins={[rehypeRaw]}>{strapiAboutProgramme.content.Title}</ReactMarkdown></p>
      </div>
      <div class="col1">
        <p><ReactMarkdown rehypePlugins={[rehypeRaw]}>{strapiAboutProgramme.content.Column1}</ReactMarkdown></p>
      </div>
      <div class="col2">
        <p><ReactMarkdown rehypePlugins={[rehypeRaw]}>{strapiAboutProgramme.content.Column2}</ReactMarkdown></p>
      </div>
      <div class="col3">
        <p><ReactMarkdown rehypePlugins={[rehypeRaw]}>{strapiAboutProgramme.content.bottom_text}</ReactMarkdown></p>
      </div>
    </div>

  </Layout>
)

export const aboutProgrammeQuesry = graphql`
  query AboutProgrammeQuery {
    strapiAboutProgramme {
      content {
        Title
        Column1
        Column2
        Bottom_text
      }
      created_at
    }
  }
`

export default AboutPetneck2Programme
